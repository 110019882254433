@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css); */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@layer base {
  @font-face {
    font-family: headingFont;
    src: url("./Fonts/Sora.ttf");
    font-display: swap;
  }
  @font-face {
    font-family: bodyText;
    src: url("./Fonts/LotaGrotesque-Regular.woff2");
    font-display: swap;
  }
}

html {
  scroll-behavior: smooth;
}
.products img {
  @apply !p-2;
}

.inputStyles {
  @apply w-full px-3 py-2 my-2 text-lg duration-200 border-2 outline-none md:text-xl md:p-3 bg-gray-50 border-gray-50 focus:border-gray-900/70;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "headingFont";
  @apply text-gray-800;
  /* background: linear-gradient(rgba(0, 0, 0, 0.621), rgba(0, 0, 0, 0.627)); */
}

svg {
  @apply w-3 md:w-auto;
  filter: invert(1) !important;
}

.btn {
  @apply px-4 py-3 mx-2 my-2 text-lg text-white duration-200 bg-gray-900 outline-none cursor-pointer md:my-5 focus:ring focus:ring-gray-600 focus:ring-offset-2;
}

td,
table {
  @apply border-0;
}

body {
  font-family: "bodyText";
  font-size: 1.2rem;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

p {
  @apply text-gray-800;
}

.block-boxShadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.img-BoxShadow {
  /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px; */
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.content-Shadow {
  box-shadow: 0px 7px 22px rgba(0, 0, 0, 0.12);
}

.slider {
  position: relative;
  width: 100%;
  height: 100% !important;
  overflow: hidden;
}

.navbar-link,
.footer-link {
  position: relative;
}
.navbar-link::after,
.footer-link:after {
  content: "";
  position: absolute;
  width: 0;
  height: 3px;
  display: block;
  margin-top: 2px;
  right: 0;
  background: #fff;
  transition: width 0.4s ease;
  -webkit-transition: width 0.4s ease;
}

.navbar-link:hover:after,
.footer-link:hover:after {
  width: 100%;
  left: 0;
  background: #fff;
}

/* .navbar-link, */
.footer-link::after {
  @apply !bg-yellowColor;
}

/* Loading Animations */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  top: -10px;
  width: 80px;
}
.lds-ellipsis div {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
